<script setup lang="ts">
import { useProjectStore } from '~/stores/projects';

const route = useRoute();
const appConfig = useAppConfig();
const { isHelpSlideoverOpen } = useDashboard();

const links = [
    {
        id: 'home',
        label: 'Home',
        icon: 'i-heroicons-home',
        to: '/admin',
        tooltip: {
            text: 'Home',
            shortcuts: ['G', 'H'],
        },
    },
    {
        id: 'announcements',
        label: 'Announcements',
        icon: 'i-heroicons-newspaper',
        to: '/admin/announcements',
        tooltip: {
            text: 'Announcements',
            shortcuts: ['G', 'N'],
        },
    },
    {
        id: 'inbox',
        label: 'Inbox',
        icon: 'i-heroicons-inbox',
        to: '/admin/inbox',
        badge: '4',
        tooltip: {
            text: 'Inbox',
            shortcuts: ['G', 'I'],
        },
    },
    {
        id: 'users',
        label: 'Users',
        icon: 'i-heroicons-user-group',
        to: '/admin/users',
        tooltip: {
            text: 'Users',
            shortcuts: ['G', 'U'],
        },
    },
    {
        id: 'settings',
        label: 'Settings',
        to: '/admin/settings',
        icon: 'i-heroicons-cog-8-tooth',
        children: [{
            label: 'General',
            to: '/settings',
            exact: true,
        }, {
            label: 'Members',
            to: '/settings/members',
        }, {
            label: 'Notifications',
            to: '/settings/notifications',
        }],
        tooltip: {
            text: 'Settings',
            shortcuts: ['G', 'S'],
        },
    },
];

const footerLinks = [{
    label: 'Invite people',
    icon: 'i-heroicons-plus',
    to: '/settings/members',
}, {
    label: 'Help & Support',
    icon: 'i-heroicons-question-mark-circle',
    click: () => isHelpSlideoverOpen.value = true,
}];

const groups = [{
    key: 'links',
    label: 'Go to',
    commands: links.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts })),
}, {
    key: 'code',
    label: 'Code',
    commands: [{
        id: 'source',
        label: 'View page source',
        icon: 'i-simple-icons-github',
        click: () => {
            window.open(`https://github.com/nuxt-ui-pro/dashboard/blob/main/pages${route.path === '/' ? '/index' : route.path}.vue`, '_blank');
        },
    }],
}];

// Projects
const projectStore = useProjectStore();
const { projects } = storeToRefs(projectStore);

onMounted(async () => {
    const localProjects = await projectStore.fetchProjects();
    if (localProjects.length > 0) {
        appConfig.app.project_selected = localProjects[0].uid;
    }
});

const colors = ['green', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet'];
const projectsLinks = computed(() => projects.value.map((project, index) => ({
    label: project.name,
    click: () => appConfig.app.project_selected = project.uid,
    chip: colors[index % colors.length],
    active: appConfig.app.project_selected === project.uid,
})));
</script>

<template>
    <UDashboardLayout>
        <UDashboardPanel
            :width="250"
            :resizable="{ min: 200, max: 300 }"
            collapsible
        >
            <UDashboardNavbar
                class="!border-transparent"
                :ui="{ left: 'flex-1' }"
            >
                <template #left>
                    <TeamsDropdown />
                </template>
            </UDashboardNavbar>

            <UDashboardSidebar>
                <template #header>
                    <UDashboardSearchButton />
                </template>

                <UDashboardSidebarLinks :links="links" />

                <UDivider />

                <UDashboardSidebarLinks
                    :links="[{ label: 'Projects', draggable: false, children: projectsLinks, collapsible: false }]"
                />

                <div class="flex-1" />

                <UDashboardSidebarLinks :links="footerLinks" />

                <UDivider class="sticky bottom-0" />

                <template #footer>
                    <!-- ~/components/UserDropdown.vue -->
                    <UserDropdown />
                </template>
            </UDashboardSidebar>
        </UDashboardPanel>

        <slot />

        <!-- ~/components/HelpSlideover.vue -->
        <HelpSlideover />
        <!-- ~/components/NotificationsSlideover.vue -->
        <NotificationsSlideover />

        <ClientOnly>
            <LazyUDashboardSearch :groups="groups" />
        </ClientOnly>
    </UDashboardLayout>
</template>

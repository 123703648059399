import { defineStore } from 'pinia';
import axios from 'axios';
import type { ProjectFromApi } from '~/types/interfaces';

export interface ProjectState {
    projects_by_uid: Record<string, ProjectFromApi>,
}

export const useProjectStore = defineStore('project', () => {
    const state = reactive<ProjectState>({
        projects_by_uid: {},
    });

    const projects = computed<Array<ProjectFromApi>>(() => Object.values(state.projects_by_uid));

    async function fetchProjects(): Promise<Array<ProjectFromApi>> {
        if (projects.value.length > 0) {
            return projects.value;
        }
        return axios.get<ProjectFromApi[]>('projects/').then((response) => {
            response.forEach((project) => {
                state.projects_by_uid[project.uid] = project;
            });
            return response;
        });
    }

    async function addProject(project: ProjectFromApi): Promise<ProjectFromApi> {
        return axios.post<ProjectFromApi>('projects/', project).then((response) => {
            state.projects_by_uid[response.uid] = response;
            return response;
        });
    }

    async function deleteProject(project_uid: string): Promise<void> {
        return axios.delete(`projects/${project_uid}/`).then(() => {
            delete state.projects_by_uid[project_uid];
        });
    }
    return {
        ...toRefs(readonly(state)),
        projects,
        fetchProjects,
        addProject,
        deleteProject,
    };
});
